<template>
    <!-- Password Inputs rely on a Vue variable to show a textbox with an open / closed eyeball icon -->

    <div
        class="password-container dm-flex-row dm-align-items-center dm-margin-bottom-lg"
        >
        <ejs-textbox
            :value="password"
            v-on:input="$emit('input', $event.value)"
            :type="isPasswordVisible ? 'text' : 'password'"
            floatLabelType="Auto"
            :placeholder="placeholder"
            v-on:keyup.enter.native="$emit('enterKeyCallback', {})">
        </ejs-textbox>
        <i
            class="fa password-visible-icon dm-pointer"
            :class="{'fa-eye': !isPasswordVisible, 'fa-eye-slash': isPasswordVisible}"
            aria-hidden="true"
            @click="toggleShowPassword">
        </i>
    </div>

    <!-- / Password Inputs rely on a Vue variable to show a textbox with an open / closed eyeball icon -->
    
</template>

<script>
    export default {
        name: 'dm-alert-modal',
        props: ['password', 'placeholder', 'enterKeyCallback'],
        data() {
            return {
                isPasswordVisible: false,
            }
        },
        methods: {
            toggleShowPassword() {
                this.isPasswordVisible = !this.isPasswordVisible;
            },
        },
    };
</script>
