import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import authorizationModule from '../../dmFramework/dmJS/dmAuthorizationModule.store.js';
import modalModule from '../../dmFramework/dmJS/dmModalModule.store.js';
import publicMenuModule from '../views/publicMenu.store.js';
import supportModule from '../views/support/support.store.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        AuthorizationModule: authorizationModule,
        ModalModule: modalModule,
        PublicMenuModule: publicMenuModule,
        SupportModule: supportModule,
    },
    state: {
        localStoragePrefix: 'menulogic-nutrition-allergen-frontend-',
    },
});

export default store;
