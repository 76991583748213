const menuModule = {
    namespaced: true,
    state: () => ({
        dto: {
            Name: '',
            PhoneNumber: '',
            EmailAddress: '',
            Message: '',
        },
    }),
    actions: {
        sendContactUsForm(dto) {
            return endpoints.sendContactUsForm(dto);
        },
    },
};

export default menuModule;
