import router from '../../src/js/router.js';

const authorizationModule = {
    namespaced: true,
    state: () => ({
        SignedInUser: {},
    }),
    mutations: {
        setUser(state, user) {
            state.SignedInUser = user;
        },
        clearUser(state) {
            state.SignedInUser = {};
        },
    },
    actions: {
        signIn({ commit }, { credentials, nextPage }) {
            return endpoints.signIn(credentials).then((user) => {
                commit('setUser', user);
                router.push({ name: nextPage });
            });
        },
        signInWithAuthToken({ commit }, { authToken, nextPage }) {
            return endpoints.signInWithAuthToken(authToken).then((user) => {
                commit('setUser', user);
                router.push({ name: nextPage });
            });
        },
        signOut({ commit }, nextPage) {
            return endpoints.signOut().then(() => {
                commit('clearUser');
                router.push({ name: nextPage });
            });
        },
        sendNewPassword({}, emailAddress) {
            return endpoints.sendNewPassword(emailAddress);
        },
        getAndSetSignedInUser({ commit }, signedInUserID) {
            return endpoints.getUserByID(signedInUserID).then((user) => {
                commit('setUser', user);
            });
        },
        getUserById({}, userID) {
            return endpoints.getUserByID(userID);
        },
        updateProfile({ commit }, dto) {
            return endpoints.updateUser(dto).then(() => {
                commit('AuthorizationModule/setUser', dto, { root: true });
            });
        },
        updatePassword({}, dto) {
            return endpoints.updatePassword(dto);
        },
    },
};

export default authorizationModule;
