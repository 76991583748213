<template>
    <ejs-dialog
        :id="modalName"
        :target="sfTarget"
        :ref="modalName"
        :header="this.modalData.title"
        isModal="true"
        visible="false"
        allowDragging="true"
        :width="'300px'"
    >
        <div class="dm-flex-column">
            <div v-html="this.modalData.message"></div>

            <div class="dm-flex-row dm-justify-content-flex-end dm-margin-top-md">
                <ejs-button isPrimary="true" class="e-outline app-mobile-btn" @click.native="btnClose_OnClick">
                    Close
                </ejs-button>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
import dmModalMixin from './dmModalMixin';

export default {
    name: 'dm-alert-modal',
    mixins: [dmModalMixin],
    methods: {
        btnClose_OnClick() {
            if (this.modalData.callback) {
                this.modalData.callback();
            }

            this.modalRef.hide();
        },
    },
};
</script>
