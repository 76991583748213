<template>
    <ejs-dialog
        :id="modalName"
        :ref="modalName"
        :target="sfTarget"
        :header="title"
        isModal="true"
        visible="false"
        showCloseIcon="true"
        allowDragging="true"
        :width="'300px'"
    >
        <div class="flex-column">
            <div class="dm-modal-form-content">
                {{ message }}
            </div>

            <div class="dm-flex-row dm-justify-content-space-between dm-margin-top-md">
                <ejs-button @click.native="btnConfirmOnClick" isPrimary="true" cssClass="app-mobile-btn">
                    {{ confirmText }}
                </ejs-button>

                <ejs-button @click.native="btnCloseOnClick" isPrimary="true" cssClass="e-outline app-mobile-btn">
                    {{ denyText }}
                </ejs-button>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
import modalMixin from './dmModalMixin';

export default {
    name: 'dm-confirm-modal',
    mixins: [modalMixin],
    computed: {
        title() {
            return this.modalData.title;
        },
        message() {
            return this.modalData.message;
        },
        confirmText() {
            return this.modalData.confirmText || 'Yes';
        },
        denyText() {
            return this.modalData.denyText || 'Close';
        },
    },
    methods: {
        btnConfirmOnClick() {
            if (typeof this.modalData.confirmCallback === 'function') {
                this.modalData.confirmCallback();
            }

            this.closeModal();
        },
        btnCloseOnClick() {
            if (typeof this.modalData.denyCallback === 'function') {
                this.modalData.denyCallback();
            }

            this.closeModal();
        },
    },
};
</script>
