<template src="./app.html"></template>

<script>
import dmAppMixin from '../dmFramework/dmJS/dmAppMixin.js';

export default {
    name: 'app',
    mixins: [dmAppMixin],
    data() {
        return {
            mnuSidebarItems: [
                // {
                //     text: 'Support',
                //     onClick: this.mnuSupportOnClick,
                //     iconCss: 'fa fa-question-circle',
                // },
            ],
            districtSlug: '',
            selectedLink: null,
            showMobileLinks: false,
            isMobileDevice: false
        };
    },
    computed: {
        activeRoute() {
            return this.$route.name;
        },
        isSigninPage() {
            return this.$route.name === 'signin';
        },
        ModalsOpened() {
            return this.$store.getters['ModalModule/ModalsOpened'];
        },
        signedInUser() {
            return this.$store.state.AuthorizationModule.SignedInUser;
        },
        publicMenu() {
            return this.$store.state.PublicMenuModule.publicMenu;
        },
    },
    watch: {
        selectedLink: function(newVal) {
            if(newVal) {
                this.goToLink(newVal);
            }
        }
    },
    methods: {
        toggleClick() {
            this.$refs.sidebarInstance.toggle();
        },
        mnuOnClick(args) {
            if (args.item.onClick) {
                args.item.onClick();
            }
        },
        mnuSupportOnClick() {
            this.$router.push({ name: 'support' });
        },
        logoOnClick() {

        },
        btnNutritionOnClick() {
            this.selectedTab = 1;
            this.$router.push('/nutrition/' + this.districtSlug);
        },
        btnAllergensOnClick() {
            this.selectedTab = 2;
            this.$router.push('/allergen/' + this.districtSlug);
        },
        btnCalendarOnClick() {
            this.selectedTab = 3;
            this.$router.push('/calendar/' + this.districtSlug);
        },
        getSites() {
            this.$store.dispatch('PublicMenuModule/getSites');
        },
        goToLink(link) {
            if(!link.startsWith('http')) {
                link = 'http://' + link;
            }
            window.open(link, '_blank');
        },
        setNavigation() {
            if(this.publicMenu.DisplayCalendar) {
                this.$router.push('/' + this.districtSlug + '/calendar');
            } else if(this.publicMenu.DisplayNutrition) {
                this.$router.push('/' + this.districtSlug + '/nutrition');
            } else if(this.publicMenu.DisplayAllergen) {
                this.$router.push('/' + this.districtSlug + '/allergen');
            } else {
                this.$router.push('/' + this.districtSlug);
            }
        },
        dropdownCreated() {
            let selects = document.getElementsByTagName('select');
            for(let i = 0; i < selects.length; i++) {
                selects[i].setAttribute('aria-label', selects[i].nextSibling.ariaLabel);
            }
        }
    },
    created() {
        this.districtSlug = this.$route.params.districtSlug;
        if(this.districtSlug) {
            this.$store.dispatch('PublicMenuModule/setDistrictSlug', this.districtSlug)
                .then(() => {
                    this.$store.dispatch('PublicMenuModule/getSites');
                    this.$store.dispatch('PublicMenuModule/getStyleDetails')
                        .then(() => {
                            document.title = this.publicMenu.PublicMenuName + ' Nutrition';

                            if(this.$route.name.includes('nutrition') && !this.publicMenu.DisplayNutrition) {
                                this.setNavigation();
                            }
                            if(this.$route.name.includes('allergen') && !this.publicMenu.DisplayAllergen) {
                                this.setNavigation();
                            }
                            if(this.$route.name.includes('calendar') && !this.publicMenu.DisplayCalendar) {
                                this.setNavigation();
                            }
                        });
                    this.$store.dispatch('PublicMenuModule/getAllergens');
                    this.$store.dispatch('PublicMenuModule/getNutrition');
                });
        }

        if(this.$route.name.includes('nutrition')) {
            this.selectedTab = 1;
        }
        if(this.$route.name.includes('allergen')) {
            this.selectedTab = 2;
        }
        if(this.$route.name.includes('calendar')) {
            this.selectedTab = 3;
        }

        this.isMobileDevice = window.innerWidth < 767;
        window.addEventListener('resize', () => {
            this.isMobileDevice = window.innerWidth < 767;
        });
    },
};
</script>

<style>
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/bootstrap.css";
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';

</style>
