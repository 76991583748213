import store from '../../src/js/store.js';

export default {
    data() {
        return {
            shouldModalStayOpen: false,
            forceModalClose: false,
            modalRef: null,
            callbackData: null,
        };
    },
    computed: {
        modalComponent() {
            return store.state.ModalModule.ModalsOpened.filter((m) => m.modalID === this.modalID)[0];
        },
        modalName() {
            return this.modalComponent.modalName;
        },
        modalData() {
            return this.modalComponent.modalData;
        },
        modalID() {
            return this.$attrs.id;
        },
        sfTarget() {
            return document.body;
        },
    },
    watch: {
        dto: {
            deep: true,
            handler(newVal, oldVal) {
                // Prevent triggering comparison between old / new value when setting the initial value of this.dto to the dto passed in as a prop.
                if (DMUtils.isObjectEmpty(oldVal) == false) {
                    // Once this.dto is set to the dto passed in as a prop, check for any differences between the two to prevent modal closing when necessary.
                    if (JSON.stringify(newVal) !== JSON.stringify(this.modalData.dto)) {
                        this.shouldModalStayOpen = true;
                    } else {
                        this.shouldModalStayOpen = false;
                    }
                }
            },
        },
    },
    mounted() {
        this.modalRef = this.$refs[this.modalName];
    },
    methods: {
        successClose(callbackData) {
            this.shouldModalStayOpen = false;
            this.closeModal(callbackData);
        },
        closeModal(callbackData) {
            if (this.shouldModalStayOpen) {
                this.preventClose();
            } else {
                this.closeModalLogic(callbackData);
            }
        },
        closeModalLogic(callbackData) {
            if (this.modalData.callback) {
                this.modalData.callback(callbackData);
            }

            if (this.clearData) {
                this.clearData();
            }

            store.commit('ModalModule/closeModal', this.$attrs.id);
            this.modalRef.hide();
        },
        preventClose(args) {
            if (this.modalComponent == null) {
                return;
            }

            if (this.forceModalClose) {
                this.closeModalLogic();
            } else if (this.shouldModalStayOpen) {
                let self = this;
                let callback = () => {
                    self.shouldModalStayOpen = false;
                    self.closeModal();
                };

                DMUtils.confirmClose(callback);

                if (args) {
                    args.cancel = this.shouldModalStayOpen;
                }
            } else {
                this.closeModalLogic();
            }
        },
    },
};
