<template src="./calendarPage.html"></template>

<script>
import Vue from 'vue';
import { Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-vue-schedule';

export default {
    name: 'calendar-page',
    provide: {
        schedule: [Day, Week, WorkWeek, Month, Agenda]
    },
    data() {
        return {
            paramsDto: {
                siteID: this.$store.state.PublicMenuModule.siteID,
                mealTimeId: this.$store.state.PublicMenuModule.mealTimeId,
                categoryId: this.$store.state.PublicMenuModule.categoryId,
            },
            dayMap: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
            ],
            days: [],
            tooltipData: {},
            weeks: [],
            selectedWeek: {},
            selectedWeekIndex: null,
            activeElement: null,
        };
    },
    computed: {
        publicMenu() {
            return this.$store.state.PublicMenuModule.publicMenu;
        },
        sites() {
            return this.$store.state.PublicMenuModule.sites;
        },
        mealTimes() {
            return this.$store.state.PublicMenuModule.mealTimes;
        },
    },
    watch: {
        selectedWeekIndex: function(newVal) {
            this.searchMenuItems();
        },
        'paramsDto.siteID': function(newVal) {
            this.$store.commit('PublicMenuModule/setSiteID', newVal);
            if(newVal) {
                this.$store.dispatch('PublicMenuModule/getMealTimes')
                    .then(response => {
                        this.paramsDto.mealTimeId = null;
                        setTimeout(() => {
                            let lunchMealPeriod = this.mealTimes.filter(m => m.DisplayName === 'Lunch')[0];
                            if(lunchMealPeriod) {
                                this.paramsDto.mealTimeId = lunchMealPeriod.MealTimeId;
                            } else {
                                this.paramsDto.mealTimeId = this.mealTimes[0].MealTimeId;
                            }
                        });
                    });
            }
        },
        'paramsDto.mealTimeId': function(newVal) {
            this.$store.commit('PublicMenuModule/setMealTimeId', newVal);
            if(newVal) {
                this.getCalendarWeeks();
            }
        }
    },
    methods: {
        searchMenuItems() {
            this.paramsDto.StartDate = this.weeks[this.selectedWeekIndex].StartDate;
            endpoints.getMenuItemsForCalendarWeek(this.paramsDto)
                .then(response => {
                    this.days = response;
                });
        },
        openTooltip(menuItemID, dailyMenuItemId, productionRecordId, $event) {
            let showTooltip = () => {
                let dayContainer = document.getElementById('app-' + this.dayMap[$event.target.attributes['data-day-number'].value]);
                let tooltipContainer = document.getElementsByClassName('app-tooltip-container')[0];
                tooltipContainer.style.display = 'block';
                if(window.innerWidth <= 576) {
                    let top = window.pageYOffset || document.documentElement.scrollTop;
                    tooltipContainer.style.top = top + 20 + 'px';
                }
                // tooltipContainer.style.left = dayContainer.getBoundingClientRect().left + dayContainer.offsetWidth + 5 + 'px';
                // if ((tooltipContainer.getBoundingClientRect().left + dayContainer.offsetWidth) > window.innerWidth) {
                //     tooltipContainer.style.left = dayContainer.getBoundingClientRect().left - 300 - 5 + 'px';
                // }
                $event.stopPropagation();

                this.activeElement = document.activeElement;
                this.$refs.tooltipModal.focus();
            }

            endpoints.getMenuItemDetailsForCalendar({
                MenuItemID: menuItemID,
                DailyMenuItemId: dailyMenuItemId || null,
                ProductionRecordId: productionRecordId || null
            })
                .then(response => {
                    this.tooltipData = response;
                    showTooltip();
                });
        },
        closeTooltip() {
            let tooltipContainer = document.getElementsByClassName('app-tooltip-container')[0];
            tooltipContainer.style.display = 'none';
            if(this.activeElement) {
                this.activeElement.focus();
            }
        },
        goToPreviousWeek() {
            this.selectedWeekIndex--;
        },
        goToNextWeek() {
            this.selectedWeekIndex++;
        },
        getCalendarWeeks() {
            endpoints.getCalendarWeeks({
                SiteID: this.paramsDto.siteID
            })
                .then(response => {
                    response.forEach((w, $index) => {
                        w.index = $index;
                    });

                    this.weeks = response;

                    if(this.selectedWeekIndex && this.weeks.filter(w => w.StartDate === this.weeks[this.selectedWeekIndex].StartDate)[0]) {
                        this.searchMenuItems();
                    } else {
                        this.selectedWeek = this.weeks.filter(w => w.IsCurrentWeek)[0];
                        let currentSelectedWeekIndex = this.selectedWeekIndex;
                        this.selectedWeekIndex = this.selectedWeek.index;
                        if(currentSelectedWeekIndex === this.selectedWeekIndex) {
                            this.searchMenuItems();
                        }
                    }
                });
        },
        filteringSchools() {
            let clearIcon = document.querySelector('.e-input-group-icon.e-clear-icon.e-icons');
            clearIcon.setAttribute('role', 'button');
            clearIcon.setAttribute('name', 'Clear filter text')
            clearIcon.setAttribute('aria-label', 'Clear filter text');

            let schoolAnnouncement = document.getElementById('schoolAnnouncement');
            setTimeout(() => {
                let count = document.querySelectorAll('.e-list-item').length;
                schoolAnnouncement.textContent = 'Filtered to ' + count + ' results';
            }, 500)
        },
        filteringWeeks() {
            let clearIcon = document.querySelector('.e-input-group-icon.e-clear-icon.e-icons');
            clearIcon.setAttribute('role', 'button');
            clearIcon.setAttribute('name', 'Clear filter text')
            clearIcon.setAttribute('aria-label', 'Clear filter text');

            let filterAnnouncement = document.getElementById('filterAnnouncement');
            setTimeout(() => {
                let count = document.querySelectorAll('.e-list-item').length;
                filterAnnouncement.textContent = 'Filtered to ' + count + ' results';
            }, 500)
        },
        btnNutritionOnClick() {
            this.$router.push('/' + this.districtSlug + '/nutrition');
        },
        btnAllergensOnClick() {
            this.$router.push('/' + this.districtSlug + '/allergen');
        },
        btnCalendarOnClick() {
            this.$router.push('/' + this.districtSlug + '/calendar');
        },
        printPage() {
            window.print();
        },
        dropdownCreated() {
            let selects = document.getElementsByTagName('select');

            for(let i = 0; i < selects.length; i++) {
                selects[i].parentElement.removeAttribute('aria-labelledby');
                selects[i].setAttribute('aria-label', selects[i].nextSibling.ariaLabel);

                if (selects[i].id === 'quickLinksDropdown_hidden') {
                    selects[i].parentElement.setAttribute('aria-label', 'Choose Quick Links');
                }

                if (selects[i].id === 'chooseMealPeriodDropdown_hidden') {
                    selects[i].parentElement.setAttribute('aria-label', 'Choose Meal Period');
                }

                if (selects[i].id === 'chooseSchoolDropdown_hidden') {
                    selects[i].parentElement.setAttribute('aria-label', 'Choose School');
                }

                if (selects[i].id === 'selectWeekDropdown_hidden') {
                    selects[i].parentElement.setAttribute('aria-label', 'Choose Week');
                }
            }
        }
    },
    created() {
        this.districtSlug = this.$route.params.districtSlug;

        if(!this.$store.state.PublicMenuModule.siteID) {
            let siteIDWatcher = this.$watch('$store.state.PublicMenuModule.siteID', (newVal) => {
                this.paramsDto.siteID = newVal;
                siteIDWatcher();
            });
        } else {
            this.getCalendarWeeks();
        }
    },
    mounted() {
        let targets = document.getElementsByClassName('app-tooltip-container');

        document.addEventListener('click', (event) => {
            let withinBoundaries = false;
            for(let i = 0; i < targets.length; i++) {
                withinBoundaries = event.composedPath().includes(targets[i]);
                if(withinBoundaries) {
                    break;
                }
            }

            for(let i = 0; i < targets.length; i++) {
                if (withinBoundaries) {
                    targets[i].style.display = 'block';
                } else {
                    targets[i].style.display = 'none';
                }
            }
        });
    },
};
</script>
