export default {

    getSites(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetSites',
            params: params,
        });
    },

    getMealTimes(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetMealTimes',
            params: params,
        });
    },

    getMenuItemCategories(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetMenuItemCategories',
            params: params,
        });
    },

    getAllergens(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetAllergens',
            params: params,
        });
    },

    getNutrition(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetNutrition',
            params: params,
        });
    },

    getMenuItemAllergens(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetMenuItemAllergens',
            params: params,
        });
    },

    getMenuItemNutrition(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetMenuItemNutrition',
            params: params,
        });
    },

    getMenuItemsForCalendar(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetMenuItemsForCalendar',
            params: params,
        });
    },

    getStyleDetails(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetDistrictDetails',
            params: params,
        });
    },

    getMenuItemDetailsForCalendar(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetMenuItemDetailsForCalendar',
            params: params,
        });
    },

    getCalendarWeeks(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetCalendarWeeks',
            params: params,
        });
    },

    getMenuItemsForCalendarWeek(params) {
        return DMUtils.http({
            method: 'GET',
            url: '/PublicMenu/GetMenuItemsForCalendarWeek',
            params: params,
        });
    },
};
