<template src="./nutritionPage.html"></template>

<script>
import Vue from 'vue';
import { TreeGridPlugin, Freeze } from "@syncfusion/ej2-vue-treegrid";
import { setTime } from '@syncfusion/ej2-vue-schedule';

export default {
    name: 'nutrition-page',
    provide: {
        treegrid: [Freeze]
    },
    data() {
        return {
            paramsDto: {
                siteID: this.$store.state.PublicMenuModule.siteID,
                mealTimeId: this.$store.state.PublicMenuModule.mealTimeId,
                categoryId: this.$store.state.PublicMenuModule.categoryId,
            },
            nutritionTableColumns: [],
            nutritionTableSortBy: {
                columns: [{ field: 'DisplayName', direction: 'Ascending' }],
            },
            nutritionTableActions: [],
            selectedRow: null,
            searchText: '',
            hideGrid: true,
        };
    },
    watch: {
        'paramsDto.siteID': function(newVal) {
            this.$store.commit('PublicMenuModule/setSiteID', newVal);
            if(newVal) {
                this.$store.dispatch('PublicMenuModule/getMealTimes')
                    .then(response => {
                        let mealTimeId = this.paramsDto.mealTimeId;
                        this.paramsDto.mealTimeId = null;
                        setTimeout(() => {
                            let hasMealTime = this.mealTimes.filter(m => m.MealTimeId === mealTimeId)[0];
                            if(!hasMealTime) {
                                let lunchMealPeriod = this.mealTimes.filter(m => m.DisplayName === 'Lunch')[0];
                                if(lunchMealPeriod) {
                                    this.paramsDto.mealTimeId = lunchMealPeriod.MealTimeId;
                                } else {
                                    this.paramsDto.mealTimeId = this.mealTimes[0].MealTimeId;
                                }
                            } else {
                                this.paramsDto.mealTimeId = mealTimeId;
                            }
                        });
                        return this.$store.dispatch('PublicMenuModule/getMenuItemCategories');
                    })
                    .then(response => {
                        this.paramsDto.categoryId = this.categories[0].CategoryId;
                    });
            }
        },
        'paramsDto.mealTimeId': function(newVal) {
            this.$store.commit('PublicMenuModule/setMealTimeId', newVal);
            if(this.paramsDto.mealTimeId && this.paramsDto.categoryId) {
                this.searchNutrition();
            }
        },
        'paramsDto.categoryId': function(newVal) {
            this.$store.commit('PublicMenuModule/setCategoryId', newVal);
            if(this.paramsDto.mealTimeId && this.paramsDto.categoryId) {
                this.searchNutrition();
            }
        },
        '$route': function(newVal) {
            let categories = []
            if(this.$route.name.includes('allergen')) {
                categories  = this.$store.state.PublicMenuModule.categories.filter(c => c.DisplayInAllergensListView);
            } else {
                categories  = this.$store.state.PublicMenuModule.categories.filter(c => c.DisplayInNutritionListView);
            }

            if(categories.length === 0) {
                return;
            }

            let pageIncludesSelectedCategory = categories.filter(c => c.CategoryId === this.paramsDto.categoryId)[0];
            if(!pageIncludesSelectedCategory) {
                this.paramsDto.categoryId = categories[0].CategoryId;
            }

            this.searchNutrition();
        },

    },
    computed: {
        publicMenu() {
            return this.$store.state.PublicMenuModule.publicMenu;
        },
        sites() {
            return this.$store.state.PublicMenuModule.sites;
        },
        mealTimes() {
            return this.$store.state.PublicMenuModule.mealTimes;
        },
        categories() {
            if(this.$route.name.includes('allergen')) {
                return this.$store.state.PublicMenuModule.categories.filter(c => c.DisplayInAllergensListView);
            } else {
                return this.$store.state.PublicMenuModule.categories.filter(c => c.DisplayInNutritionListView);
            }
        },
        nutritionTableItems() {
            this.setColumns();
            setTimeout(this.setHeaderHeight);

            let items = DMUtils.copy(this.$store.state.PublicMenuModule.nutritionItems);
            if(this.searchText) {
                items.forEach(item => {
                    item.ChildItems = item.ChildItems.filter(childItem => {
                        return childItem.DisplayName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || item.DisplayName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase());
                    });
                });

                items = items.filter(item => {
                    return item.DisplayName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) || item.ChildItems.length > 0;
                });
            }

            return items;
        },
    },
    methods: {
        nutritionTableRowOnClick(args) {
            this.selectedRow = args;
        },
        searchNutrition() {
            let endpoint = 'getMenuItemNutrition';
            if(this.$route.name.includes('allergen')) {
                endpoint = 'getMenuItemAllergens';
            }
            this.hideGrid = true;
            this.$store.dispatch('PublicMenuModule/' + endpoint)
                .then(() => {
                    this.hideGrid = false;
                });
        },
        viewShiftsForInvoice() {

        },
        nutritionTableActionOnClick(args) {

        },
        filteringSchools() {
            let clearIcon = document.querySelector('.e-input-group-icon.e-clear-icon.e-icons');
            clearIcon.setAttribute('role', 'button');
            clearIcon.setAttribute('name', 'Clear filter text')
            clearIcon.setAttribute('aria-label', 'Clear filter text');

            let schoolAnnouncement = document.getElementById('schoolAnnouncement');
            setTimeout(() => {
                let count = document.querySelectorAll('.e-list-item').length;
                schoolAnnouncement.textContent = 'Filtered to ' + count + ' results';
            }, 500)
        },
        setColumns() {
            if(this.$route.name.includes('allergen')) {
                this.nutritionTableColumns = this.$store.state.PublicMenuModule.allergenColumns;
            } else {
                this.nutritionTableColumns = this.$store.state.PublicMenuModule.nutritionColumns;
            }

            setTimeout(() => {
                this.setHeaderHeight();
                let columns = document.querySelectorAll('.e-gridcontent table colgroup col');
                if(columns && this.nutritionTableItems.length > 0) {
                    for(let i = 0; i < columns.length; i++) {
                        if(i % 2) {
                            columns[i].style['background-color'] = this.publicMenu.PrimaryColor + '1A';
                        }
                    }
                }
            }, 100);
        },
        setHeaderHeight() {
            let textHeaders = document.querySelectorAll('.e-headertext');
            let textWidths = [];
            textHeaders.forEach(t => {
               textWidths.push(t.offsetWidth);
            });
            let largestWidth = Math.max(...textWidths);
            let headerCell = document.querySelectorAll('.e-headercell');
            for(let i = 0; i < headerCell.length; i++) {
               (headerCell.item(i)).style.height = largestWidth - (largestWidth/11) + 'px';
            }

            let moreDetails = document.querySelectorAll('.app-more-details');
            for(let i = 0; i < moreDetails.length; i++) {
                moreDetails[i].style.color = this.publicMenu.PrimaryColor;
            }
        },
        setSelectedButton(id) {
            let btns = document.querySelectorAll('.app-navigation-button');

            btns.forEach((btn) => {
                if (btn.id === id) {
                    btn.ariaSelected = 'true';
                } else {
                    btn.ariaSelected = 'false';
                }
            });
        },
        btnNutritionOnClick() {
            this.$router.push('/' + this.districtSlug + '/nutrition');
            this.setSelectedButton('btnNutrition')
        },
        btnAllergensOnClick() {
            this.$router.push('/' + this.districtSlug + '/allergen');
            this.setSelectedButton('btnAllergen')
        },
        btnCalendarOnClick() {
            this.$router.push('/' + this.districtSlug + '/calendar');
        },
        printPage() {
            window.print();
        },
        dataBound(arg) {
            let ths = this.$refs.treeGrid.$el.querySelectorAll('th');
            for(let i = 0; i < ths.length; i++) {
                ths[i].removeAttribute('aria-label');
                ths[i].removeAttribute('tabindex');
                ths[i].removeAttribute('aria-expanded');
            }

            let tr = this.$refs.treeGrid.$el.querySelectorAll('tr');
            for(let i = 0; i < tr.length; i++) {
                tr[i].removeAttribute('aria-label');
                tr[i].removeAttribute('tabindex');
                tr[i].removeAttribute('aria-expanded');
            }

            let tds = this.$refs.treeGrid.$el.querySelectorAll('td');
            for(let i = 0; i < tds.length; i++) {
                tds[i].removeAttribute('aria-label');
                tds[i].removeAttribute('tabindex');
                tds[i].removeAttribute('aria-expanded');

                // tds[i].addEventListener
            }

            this.$refs.treeGrid.$el.querySelector('.e-content').setAttribute('tabindex', '0');

            let expandIcons = this.$refs.treeGrid.$el.querySelectorAll('.e-treegridexpand');
            for(let i = 0; i < expandIcons.length; i++) {
                // expandIcons[i].setAttribute('tabindex', '0');
                let parent = expandIcons[i].parentNode;
                let button = document.createElement('button');
                button.setAttribute('aria-label', 'Expand/Collapse');
                button.classList.add('app-expand-collapse');
                parent.replaceChild(button, expandIcons[i]);
                button.appendChild(expandIcons[i]);
                button.addEventListener('keydown', (e) => {
                    if (e.keyCode === 13) {
                        expandIcons[i].click();
                    } else {
                        // console.dir(button);
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();
                        // button.nextSibling.nextSibling.setAttribute('aria-label', button.nextSibling.nextSibling.innerHTML);
                        setTimeout(() => {
                            button.nextSibling.nextSibling.focus();
                        });
                    }
                });

                let parent2 = button.parentNode;
                let button2 = document.createElement('button');
                button2.setAttribute('aria-label', button.nextSibling.nextSibling.innerHTML);
                button2.innerHTML = button.nextSibling.nextSibling.innerHTML;
                button.nextSibling.nextSibling.style.display = 'none';
                parent2.appendChild(button2);
                button.appendChild(button.nextSibling.nextSibling);
            }
        },
        dropdownCreated() {
            let selects = document.getElementsByTagName('select');

            for(let i = 0; i < selects.length; i++) {
                selects[i].parentElement.removeAttribute('aria-labelledby');
                selects[i].setAttribute('aria-label', selects[i].nextSibling.ariaLabel);

                if (selects[i].id === 'quickLinksDropdown_hidden') {
                    selects[i].parentElement.setAttribute('aria-label', 'Quick Links');
                }

                if (selects[i].id === 'chooseMealPeriodDropdown_hidden') {
                    selects[i].parentElement.setAttribute('aria-label', 'Choose Meal Period');
                }

                if (selects[i].id === 'chooseSchoolDropdown_hidden') {
                    selects[i].parentElement.setAttribute('aria-label', 'Choose School');
                }
            }
        }
    },
    created() {
        this.districtSlug = this.$route.params.districtSlug;

        if(!this.$store.state.PublicMenuModule.siteID) {
            let siteIDWatcher = this.$watch('$store.state.PublicMenuModule.siteID', (newVal) => {
                this.paramsDto.siteID = newVal;
                siteIDWatcher();
            });
        } else {
            this.$store.dispatch('PublicMenuModule/getMenuItemCategories')
                .then(() => {
                    if(this.paramsDto.categoryId !== this.categories[0].CategoryId) {
                        this.paramsDto.categoryId = this.categories[0].CategoryId;
                    } else {
                        this.searchNutrition();
                    }
                });
        }

        

        this.setColumns();
    },
    mounted() {
        this.setHeaderHeight();

        setTimeout(() => {
            if (this.$route.name === 'nutrition') {
                this.setSelectedButton('btnNutrition');
            }
    
            if (this.$route.name === 'allergen') {
                this.setSelectedButton('btnAllergen');
            }
        }, 1000)
    },
};
</script>
