/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';

import homePage from '../views/homePage/homePage';
import nutritionPage from '../views/nutritionPage/nutritionPage';
import calendarPage from '../views/calendarPage/calendarPage';
import supportPage from '../views/support/supportPage/supportPage';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/:districtSlug/calendar',
        },
        {
            path: '/:districtSlug',
            redirect: '/:districtSlug/calendar',
        },
        {
            path: '/:districtSlug/nutrition',
            name: 'nutrition',
            component: nutritionPage,
        },
        {
            path: '/:districtSlug/allergen',
            name: 'allergen',
            component: nutritionPage,
        },
        {
            path: '/:districtSlug/calendar',
            name: 'calendar',
            component: calendarPage,
        },
        {
            path: '/support',
            name: 'support',
            component: supportPage,
        },
    ],
});

export default router;
