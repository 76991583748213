<template src="./supportPage.html"></template>

<script>
export default {
    name: 'support-page',
    data() {
        return {
            dto: {},
            dtoIsDirty: false,
        };
    },
    watch: {
        dto: function (val) {
            if (val) {
                this.dtoIsDirty = true;
            }
        },
    },
    methods: {
        btnSaveOnClick() {
            this.$store.dispatch('SupportModule/sendContactUsForm', this.dto).then(() => {
                this.$router.go(-1);
            });
        },
        btnCancelOnClick() {
            if (this.dtoIsDirty) {
                const message = 'You have unsaved changes. Are you sure you want to close?';
                const title = 'Confirm';
                const confirmCallback = this.leavePage;
                DMUtils.openApplicationModal('dmConfirmModal', {
                    title: title,
                    message: message,
                    confirmCallback: confirmCallback,
                    confirmText: 'Yes',
                    denyText: 'No',
                });
            } else {
                this.leavePage();
            }
        },
        leavePage() {
            this.$router.go(-1);
        },
    },
};
</script>
