import router from "../js/router";

const publicMenuModule = {
    namespaced: true,
    state: () => ({
        districtSlug: '',
        publicMenu: {},
        sites: [],
        siteID: null,
        mealTimes: [],
        mealTimeId: null,
        categories: [],
        categoryId: null,
        nutritionItems: [],
        allergenColumns: [],
        nutritionColumns: []
    }),
    mutations: {
        setDistrictSlug(state, districtSlug) {
            state.districtSlug = districtSlug;
        },
        setPublicMenu(state, publicMenu) {
            state.publicMenu = publicMenu;
        },
        setSites(state, sites) {
            state.sites = sites;
        },
        setSiteID(state, siteID) {
            state.siteID = siteID;
        },
        setMealTimes(state, mealTimes) {
            state.mealTimes = mealTimes;
        },
        setMealTimeId(state, mealTimeId) {
            state.mealTimeId = mealTimeId;
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        setCategoryId(state, categoryId) {
            state.categoryId = categoryId;
        },
        setNutritionItems(state, nutritionItems) {
            state.nutritionItems = nutritionItems;
        },
        setAllergenColumns(state, allergenColumns) {
            state.allergenColumns = allergenColumns;
        },
        setNutritionColumns(state, nutritionColumns) {
            state.nutritionColumns = nutritionColumns;
        }
    },
    actions: {
        setDistrictSlug({ commit }, districtSlug) {
            commit('setDistrictSlug', districtSlug);
        },
        getStyleDetails({ commit, state }) {
            return endpoints.getStyleDetails({districtSlug: state.districtSlug})
                .then((response) => {
                    let style = document.createElement('style');
                    style.type = 'text/css';
                    style.innerHTML = '.app-primary-color { color: ' + response.PrimaryColor + ' !important; }';
                    document.getElementsByTagName('head')[0].appendChild(style);

                    if(!response.IsPublicMenuPublished) {
                        response.HeaderText = response.PreviewModeText;
                    }
                    commit('setPublicMenu', response);
                });
        },
        getSites({ commit, state }) {
            return endpoints.getSites({districtSlug: state.districtSlug})
                .then((response) => {
                    commit('setSites', response);
                });
        },
        getMealTimes({ commit, state }) {
            return endpoints.getMealTimes({SiteID: state.siteID})
                .then((response) => {
                    commit('setMealTimes', response);
                    commit('setMealTimeId', response[0].MealTimeId);
                });
        },
        getMenuItemCategories({ commit, state }) {
            return endpoints.getMenuItemCategories({SiteID: state.siteID})
                .then((response) => {
                    response.forEach(c => {
                        c.IconUrl += '?v=' + new Date().toISOString();
                    });
                    commit('setCategories', response);
                    commit('setCategoryId', response[0].CategoryId);
                });
        },
        getMenuItemAllergens({ commit, state }) {
            return endpoints.getMenuItemAllergens({
                SiteID: state.siteID,
                MealTimeId: state.mealTimeId,
                CategoryId: state.categoryId
            })
                .then((response) => {
                    commit('setNutritionItems', response);
                });
        },
        getMenuItemNutrition({ commit, state }) {
            return endpoints.getMenuItemNutrition({
                SiteID: state.siteID,
                MealTimeId: state.mealTimeId,
                CategoryId: state.categoryId
            })
                .then((response) => {
                    commit('setNutritionItems', response);
                });
        },
        getAllergens({ commit, state }) {
            return endpoints.getAllergens({districtSlug: state.districtSlug})
                .then((response) => {
                    response.forEach(a => {
                        Object.assign(a, {
                            key: a.AllergenName,
                            classes: {
                                class: 'orientationcss'
                            },
                            width: '70px',
                            formatter(column, data) {
                                let allergen = data.Allergens.filter(al => al.Key === a.AllergenName)[0];
                                if(allergen && allergen.Value === true) {
                                    return '<i class="fa fa-check"></i>';
                                } else if(allergen && allergen.Value === null) {
                                    return '*'
                                } else {
                                    return '';
                                }
                            },
                            textAlign: 'Center'
                        });
                    });

                    let minWidth = '400px';
                    if(window.innerWidth <= 576) {
                        minWidth = '200px';
                    }

                    response.unshift({
                        key: 'DisplayName',
                        label: '',
                        minWidth: minWidth,
                        isFrozen: true
                    });

                    response.push({
                        key: 'MoreDetailsUrl',
                        label: '',
                        formatter(column, data) {
                            if(!data.MoreDetailsUrl) {
                                return '';
                            }
                            return '<a href=\"' + data.MoreDetailsUrl + '/" target="_blank" class="app-font-xs app-more-details app-primary-color">More&nbsp;Details</a>';
                        }
                    });

                    response.push({
                        key: 'Filler',
                        label: ''
                    });

                    commit('setAllergenColumns', response);
                });
        },
        getNutrition({ commit, state }) {
            return endpoints.getNutrition({districtSlug: state.districtSlug})
                .then((response) => {
                    response.forEach(n => {
                        Object.assign(n, {
                            key: n.NutritionName,
                            classes: {
                                class: 'orientationcss'
                            },
                            width: '70px',
                            formatter(column, data) {
                                return data.NutritionAmounts.filter(a => a.NutritionName === n.NutritionName)[0].AmountF;
                            },
                            textAlign: 'Center'
                        });
                    });

                    response.unshift({
                        key: 'ServingDisplay',
                        label: 'Serving Size',
                        classes: {
                            class: 'orientationcss'
                        },
                        width: '90px',
                        textAlign: 'Center'
                    });

                    let minWidth = '400px';
                    if(window.innerWidth <= 576) {
                        minWidth = '200px';
                    }

                    response.unshift({
                        key: 'DisplayName',
                        label: '',
                        minWidth: minWidth,
                        isFrozen: true
                    });

                    response.push({
                        key: 'Filler',
                        label: ''
                    });

                    commit('setNutritionColumns', response);
                });
        }
    },
};

export default publicMenuModule;
